// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('jquery');
require('popper.js');
var moment = require('moment');

require('markdown/index');
require('./bootstrap_custom');
require('./bootstrap-table');

import ClipboardJS from 'clipboard/dist/clipboard.min.js';
import 'bootstrap-table/dist/bootstrap-table.min.css';
import '../stylesheets/application';
import '../stylesheets/bootstrap-datepicker';

$(document).on('ready turbolinks:load', function() {

  // refresh CSRF tokens for correct testing
  $.rails.refreshCSRFTokens();

  // Update Filename for FileUpload inputs
  $('.custom-file-input').on('change', function(e){
    const fileName = e.target.files[0].name;
    $(this).next('.custom-file-label').html(fileName);
  })

  // Enable Copy-To-Clipboard Buttons
  const clipboard = new ClipboardJS('.copy-btn');
  clipboard.on('success', function(e) {
    $('.copy-btn').removeClass('copied');
    $(e.trigger).addClass('copied');
  });

  // Initialize Bootstrap Table & Filter
  $('[data-toggle="table"]').bootstrapTable();
  $('#filter-toggle').on('click', function() {
    $('.filter-control').toggle();
  });

  // Initialize Multiline Placeholders for Textareas
  $('textarea.multiline-placeholder').each(function() {
    $(this).attr('placeholder', $(this).attr('placeholder').replace(/\\n/g, '\n'));
  });

  // Add custom date sorter for bootstrap tables
  function dateSorter(a, b) {
    const dateA = moment(a, 'DD.MM.YYYY');
    const dateB = moment(b, 'DD.MM.YYYY');
    if (!dateA.isValid() && !dateB.isValid()) return 0;
    if (!dateA.isValid() || dateA < dateB) return 1;
    if (!dateB.isValid() || dateA > dateB) return -1;
    return 0;
  }
  window.dateSorter = dateSorter;

  // Combine Inputs with prefix and suffix
  $('input[data-input]').each(function() {
    const combined = $(this);
    $('input#' + combined.data('input')).on('keyup', function() {
      combined.val(combined.data('prefix') + $(this).val() + combined.data('suffix'));
    });
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
