import SimpleMDE from 'simplemde/dist/simplemde.min.js'
import 'simplemde/dist/simplemde.min.css'

$(document).on('ready turbolinks:load', function() {
  if ($('.mde')[0]) {
    const simplemde = new SimpleMDE({
      element: $('.mde')[0],
      promptURLs: true,
      showIcons: ['table'],
      toolbar: [{
        name: 'bold',
        action: SimpleMDE.toggleBold,
        className: 'fa fa-bold',
        title: 'Bold',
      }, {
        name: 'italic',
        action: SimpleMDE.toggleItalic,
        className: 'fa fa-italic',
        title: 'Italic',
      }, {
        name: 'heading',
        action: SimpleMDE.toggleHeadingSmaller,
        className: 'fa fa-header',
        title: 'Heading',
      }, '|', {
        name: 'quote',
        action: SimpleMDE.toggleBlockquote,
        className: 'fa fa-quote-left',
        title: 'Quote',
      }, {
        name: 'unordered-list',
        action: SimpleMDE.toggleUnorderedList,
        className: 'fa fa-list-ul',
        title: 'Generic List',
      }, {
        name: 'ordered-list',
        action: SimpleMDE.toggleOrderedList,
        className: 'fa fa-list-ol',
        title: 'Numbered List',
      }, '|', {
        name: 'link',
        action: SimpleMDE.drawLink,
        className: 'fa fa-link',
        title: 'Create Link',
      }, {
        name: 'image',
        action: SimpleMDE.drawImage,
        className: 'fa fa-picture-o',
        title: 'Insert Image',
      }, {
        name: 'table',
        action: SimpleMDE.drawTable,
        className: 'fa fa-table',
        title: 'Insert Table',
      }, '|', {
        name: 'preview',
        action: SimpleMDE.togglePreview,
        className: 'fa fa-eye no-disable',
        title: 'Create Link',
      }, {
        name: 'side-by-side',
        action: SimpleMDE.toggleSideBySide,
        className: 'fa  fa-columns no-disable no-mobile',
        title: 'Insert Image',
      }, {
        name: 'fullscreen',
        action: SimpleMDE.toggleFullScreen,
        className: 'fa fa-arrows-alt no-disable no-mobile',
        title: 'Insert Table',
      }, '|', {
        name: 'guide',
        action: 'https://simplemde.com/markdown-guide',
        className: 'fa fa-question-circle',
        title: 'Markdown Guide',
      // }, {
      //   name: 'custom',
      //   action: function customFunction(editor) {
      //     // Add your own code
      //     console.log('EDITOR', editor);
      //   },
      //   className: 'fa fa-star',
      //   title: 'Custom Button',
      },
    ]});
  }
});
